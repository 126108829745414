import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './guards/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './guards/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import { ChannelCategoryEnum } from './types/shared';
import { MenuRoutes } from './types/routes';
import RoleGuard from './guards/RoleGuard';
import TextGuard from './guards/TextGuard';
import { Roles } from './utils/constants';
import Forbidden from './pages/Forbidden';
import ManageSkill from './pages/ManageSkill';
import ManageUser from './pages/ManageUser';
import ManageTextEntryPoint from './pages/ManageTextEntryPoint';
import SkillGuard from './guards/SkillGuard';
import MessagingFlowsGuard from './guards/MessagingFlowsGuard';

const Loadable = (Component) => (props) => (
	<Suspense fallback={<LoadingScreen />}>
		<Component {...props} />
	</Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')));
const Queues = Loadable(lazy(() => import('./pages/Queues')));
const Flow = Loadable(lazy(() => import('./pages/Flow')));
const GeneralSettings = Loadable(lazy(() => import('./pages/GeneralSettings')));
const Contacts = Loadable(lazy(() => import('./pages/Contacts')));
const Skills = Loadable(lazy(() => import('./pages/Skills')));
const MediaFiles = Loadable(lazy(() => import('./pages/MediaFiles')));
const ManageMediaFile = Loadable(lazy(() => import('./pages/ManageMediaFile')));
const AddEditBotApplicationInstance = Loadable(lazy(() => import('./pages/BotApplicationInstance')));
const ManageFlow = Loadable(lazy(() => import('./pages/ManageFlow')));
const DesignCallFlow = Loadable(lazy(() => import('./pages/DesignCallFlow')));
const DesignTextFlow = Loadable(lazy(() => import('./pages/DesignTextFlow')));
const ManageSchedule = Loadable(lazy(() => import('./pages/ManageSchedule')));
const Schedule = Loadable(lazy(() => import('./pages/Schedule')));
const UserList = Loadable(lazy(() => import('./pages/UserList')));
const ManageQueue = Loadable(lazy(() => import('./pages/ManageQueue')));
const Event = Loadable(lazy(() => import('./pages/Event')));
const ManageEvent = Loadable(lazy(() => import('./pages/ManageEvent')));
const EntryPoint = Loadable(lazy(() => import('./pages/EntryPoint')));
const Tenants = Loadable(lazy(() => import('./pages/Tenants')));
const ManageTenant = Loadable(lazy(() => import('./pages/ManageTenant')));
const WrapUp = Loadable(lazy(() => import('./pages/WrapUp')));
const WrapUpCollection = Loadable(lazy(() => import('./pages/WrapUpCollection')));
const AddEditGenericEntryPoint = Loadable(lazy(() => import('./pages/GenericEntryPoint')));
const MessagingQueue = Loadable(lazy(() => import('./pages/MessagingQueue')));
const MessagingFlow = Loadable(lazy(() => import('./pages/MessagingFlow')));
const ManageMessagingFlow = Loadable(lazy(() => import('./pages/ManageMessagingFlow')));
const Templates = Loadable(lazy(() => import('./pages/Templates')));
const ManageTemplate = Loadable(lazy(() => import('./pages/ManageTemplate')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: RouteObject[] = [
	{
		path: MenuRoutes.Authentication,
		children: [
			{
				path: MenuRoutes.Login,
				element: (
					<GuestGuard>
						<Login />
					</GuestGuard>
				)
			},
			{
				path: MenuRoutes.LoginMultitenant,
				element: (
					<Login />
				)
			},
			{
				path: MenuRoutes.LoginUnguarded,
				element: <Login />
			}
		]
	},
	{
		path: MenuRoutes.Calls,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Queues,
				element: <Queues key="queues-view" type={ChannelCategoryEnum.Voice} />
			},
			{
				path: MenuRoutes.NewQueue,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageQueue type={ChannelCategoryEnum.Voice} />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditQueue,
				element: <ManageQueue />
			},
			{
				path: MenuRoutes.Flows,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<Flow />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewFlow,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageFlow />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditFlow,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageFlow />
					</RoleGuard>
			},
			{
				path: MenuRoutes.DesignFlow,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<DesignCallFlow />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewAppInstance,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<AddEditBotApplicationInstance />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EntryPoints,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<EntryPoint type={ChannelCategoryEnum.Voice} />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewEntryPoint,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<AddEditBotApplicationInstance />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditEntryPoint,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<AddEditBotApplicationInstance />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Generic,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Queues,
				element: <Queues key="generic-queues-view" type={ChannelCategoryEnum.Generic} />
			},
			{
				path: MenuRoutes.NewQueue,
				element: <MessagingQueue type={ChannelCategoryEnum.Generic} />
			},
			{
				path: MenuRoutes.EditQueue,
				element: <MessagingQueue type={ChannelCategoryEnum.Generic} />
			},
			{
				path: MenuRoutes.EntryPoints,
				element: <EntryPoint type={ChannelCategoryEnum.Generic} />
			},
			{
				path: MenuRoutes.NewEntryPoint,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<AddEditGenericEntryPoint />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditEntryPoint,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<AddEditGenericEntryPoint />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Text,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Queues,
				element: 
					<TextGuard>
						<Queues type={ChannelCategoryEnum.Text} />
					</TextGuard>
			},
			{
				path: MenuRoutes.Flows,
				element: 
					<TextGuard>
						<MessagingFlowsGuard>
							<MessagingFlow />
						</MessagingFlowsGuard>
					</TextGuard>
			},
			{
				path: MenuRoutes.EntryPoints,
				element: 
					<TextGuard>
						<EntryPoint type={ChannelCategoryEnum.Text} />
					</TextGuard>
			},
			{
				path: MenuRoutes.NewQueue,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<TextGuard>
							<MessagingQueue type={ChannelCategoryEnum.Text} />
						</TextGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewFlow,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<MessagingFlowsGuard>				
							<ManageMessagingFlow />
						</MessagingFlowsGuard>		
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditFlow,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<MessagingFlowsGuard>	
							<ManageMessagingFlow />
						</MessagingFlowsGuard>	
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditQueue,
				element:
					<TextGuard>
						<MessagingQueue type={ChannelCategoryEnum.Text} />
					</TextGuard>
			},
			{
				path: MenuRoutes.NewEntryPoint,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<TextGuard>
							<ManageTextEntryPoint />
						</TextGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditEntryPoint,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<TextGuard>
							<ManageTextEntryPoint />
						</TextGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.DesignFlow,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<MessagingFlowsGuard>	
							<DesignTextFlow />
						</MessagingFlowsGuard>	
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Schedules,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Schedules,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<Schedule />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewSchedule,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageSchedule />
					</RoleGuard>,
			},
			{
				path: MenuRoutes.EditSchedule,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageSchedule />
					</RoleGuard>,
			}
		]
	},
	{
		path: MenuRoutes.Events,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Events,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<Event />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewEvent,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageEvent />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EdiEvent,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageEvent />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.GeneralSettings,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.GeneralSettings,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<GeneralSettings />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Contacts,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Contacts,
				element:
					<RoleGuard roles={[Roles.WebAdmin, Roles.Supervisor]}>
						<Contacts />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Skills,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Skills,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<SkillGuard>
							<Skills />
						</SkillGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewSkill,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<SkillGuard>
							<ManageSkill />
						</SkillGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditSkill,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<SkillGuard>
							<ManageSkill />
						</SkillGuard>
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.MediaFiles,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.MediaFiles,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<MediaFiles />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewMediaFile,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageMediaFile />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditMediaFile,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageMediaFile />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditDynamicMediaFile,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<ManageMediaFile />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.WrapUp,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard >
		),
		children: [
			{
				path: MenuRoutes.WrapUp,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<WrapUp />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewWrapUp,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<WrapUpCollection />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditWrapUp,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<WrapUpCollection />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Users,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Users,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<UserList />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditUser,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<SkillGuard>
							<ManageUser />
						</SkillGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditMember,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<SkillGuard>
							<ManageUser />
						</SkillGuard>
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Tenants,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Tenants,
				element:
					<RoleGuard roles={[Roles.WebAdmin, Roles.CC4AllAdmin]} enforceAllRoles={true}>
						<Tenants />
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewTenant,
				element:
					<RoleGuard roles={[Roles.WebAdmin, Roles.CC4AllAdmin]} enforceAllRoles={true}>
						<ManageTenant />
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditTenant,
				element:
					<RoleGuard roles={[Roles.WebAdmin, Roles.CC4AllAdmin]} enforceAllRoles={true}>
						<ManageTenant />
					</RoleGuard>
			}
		]
	},
	{
		path: MenuRoutes.Dashboard,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: MenuRoutes.Dashboard,
				element: <Dashboard />
			},
			{
				path: MenuRoutes.Account,
				element: <Account />
			},
		]
	},
	{
		path: MenuRoutes.Templates,
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard >
		),
		children: [
			{
				path: MenuRoutes.Templates,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<TextGuard>
						     <Templates />
						</TextGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.NewTemplate,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<TextGuard>
							<ManageTemplate />
						</TextGuard>
					</RoleGuard>
			},
			{
				path: MenuRoutes.EditTemplate,
				element:
					<RoleGuard roles={[Roles.WebAdmin]}>
						<TextGuard>
							<ManageTemplate />
						</TextGuard>
					</RoleGuard>
			}
		]
	},
	{
		path: '*',
		element:
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>,
		children: [
			{
				path: '*',
				element: (
					<Navigate
						to={MenuRoutes.Calls}
						replace
					/>
				)
			},
			{
				path: '401',
				element: <AuthorizationRequired />
			},
			{
				path: '403',
				element: <Forbidden />
			},
			{
				path: '404',
				element: <NotFound />
			},
			{
				path: '500',
				element: <ServerError />
			},
			{
				path: '*',
				element: <NotFound />
			}
		]
	}
];

export default routes;
